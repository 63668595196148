import React from 'react';
import { OptionCard, RegContainer, Info } from '../../../../components';
import { BackButton, GU } from '@aragon/ui';
import { Link, Row, Title2, Label, ExternalLink } from '../../../../ui';
import { useTheme } from '@aragon/ui';
import useStyles from '../../investor/styles';
import textStyle from '../../../../utils/textStyle';
import { COLORS } from '../../../../utils/static';
import CreatePersonalIcon from '../../../../assets/entry-pages/create-personal.svg';
import { useTranslation } from 'react-i18next';

export const AssistantRegStart = ({ history }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <RegContainer type='centered'>
      <Title2 marginBottom={7 * GU}>{t('Create your own assistant account in a few minutes!')}</Title2>

      <Row margin={-GU} justifyContent={'center'}>
        <OptionCard
          title={t('Create assistant \naccount')}
          className={'col-auto'}
          onClick={() => {
            history.push('/registration/assistant/1', { account: 'RECOMMENDER' });
          }}
          icon={CreatePersonalIcon}
        />
      </Row>

      <Info title={<Label color={COLORS.aragonDarkBlue}>{t('Disclaimer')}</Label>}>
        {t('The Service is generally available only to persons of legal age and legal capacity under local law.')}
        {t('If you have any questions, please contact us')} - <ExternalLink href={'mailto:office@spiritcapital.eu'}
                                                                            external>
        office@spiritcapital.eu
      </ExternalLink>
        <br />
      </Info>

      <BackButton
        className={styles.backButton}
        onClick={() => {
          history.push('/');
        }}
        label={t('Back')}
      />

      <div className={styles['login-title']} style={textStyle('body1')}>
        {t('Already have an account?')} <Link to={'/login'}>{t('Log in')}</Link>.
      </div>
    </RegContainer>
  );
};
