import React, { useMemo } from 'react';
import { Box } from '../../../customAragon';
import {Body2Light, Body3Light, Button, Col, Row, Title4, Container, Title5, Text} from '../../../../ui';
import styled from 'styled-components';
import { COLORS } from '../../../../utils/static';
import { dateString } from '../../../../utils/helpers/common';
import {STATUS_BUTTON_MODE, STATUS_COLOR, STATUS_DISPLAY} from "../../../../utils/static/kyc";
import { useTranslation } from 'react-i18next';

export const DocumentBox = ({
  data,
  number,
  title = '',
  status = '',
  error = '',
  enabled,
  onClick,
  ...props
}) => {
  const { t } = useTranslation();
  const boxTitle = title || data?.name || data?.kycTypeDocument?.name;
  const boxStatus = status || data?.status;
  const boxEnabled = enabled || data?.enabled;
  const boxError = error || data?.error;

  return (
    <StyledBox {...props} active={boxEnabled} bgColor={STATUS_COLOR[boxStatus]}>
      <Row margin={-7} nowrap height={'100%'}>
        {number && (
          <Col auto>
            <NumberCircle active={boxEnabled}>{number}</NumberCircle>
          </Col>
        )}
        <Col auto style={{ overflow: 'hidden' }}>
          <Row flexDirection={'column'} justifyContent={'space-between'} nowrap height={'100%'}>
            <Col marginBottom={16}>
              <Title weight={300} color={COLORS.default}>
                {boxTitle}
              </Title>
              {data?.description && <Title4 color={COLORS.default}>{data?.description}</Title4>}
            </Col>

            <Container>
              {boxError && (
                <Body3Light marginBottom={10}>
                  Comment:{' '}
                  <Text color={COLORS.red} fontWeight={600} inline>
                    {boxError}
                  </Text>
                </Body3Light>
              )}

              <Body2Light>
                {t('Status')}:{' '}
                <Text color={STATUS_COLOR[boxStatus] || COLORS.aragonBlue} inline fontWeight={700}>
                  {status || t(STATUS_DISPLAY[boxStatus])}
                </Text>
              </Body2Light>

              <Body2Light marginTop={10}>
                {t('Created')}:{' '}
                <Text inline fontWeight={400}>
                  {data?.created ? dateString(new Date(data?.created)) : '-'}
                </Text>
              </Body2Light>

              <Body2Light>
                {t('Updated')}:{' '}
                <Text inline fontWeight={400}>
                  {data?.updated ? dateString(new Date(data?.updated)) : '-'}
                </Text>
              </Body2Light>
              <Body2Light>
                {t('Expiration date')}:{' '}
                <Text inline fontWeight={400}>
                  {data?.expiration ? dateString(new Date(data?.expiration)) : '-'}
                </Text>
              </Body2Light>

              <Container marginTop={16}>
                <Button
                  label={t('Open')}
                  onClick={onClick}
                  size={'small'}
                  mode={STATUS_BUTTON_MODE[boxStatus] || 'strong'}
                  fontWeight={300}
                  disabled={!boxEnabled}
                />
              </Container>
            </Container>
          </Row>
        </Col>
      </Row>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  border-radius: 12px;
  overflow: hidden;
  filter: drop-shadow(8px 12px 16px rgba(0, 0, 0, 0.08));
  border: none;
  height: 100%;

  :after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 6px;
    width: 100%;
    // background: ${(p) => (p.active ? COLORS.aragonBlue : '#F1F3F7')};
    background: ${p => p.bgColor ? p.bgColor : COLORS.aragonBlue};
  }
  
  > div,
  > div > div {
    height: 100%;
  }
`;
const Title = styled(Title5)`
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  font-weight: 400;
`;
const NumberCircle = styled.div`
  width: 24px;
  height: 24px;
  padding: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
  flex-grow: 0;
  font-size: 14px;
  font-weight: 300;
  color: ${COLORS.white};
  background: ${(p) =>
    p.active ? 'linear-gradient(200.38deg, #32FFF5 -153.94%, #08BEE5 84.51%)' : COLORS.greyLight};
`;
