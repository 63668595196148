import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button, Checkbox, Input, Modal, Popconfirm, Radio, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { history } from '../../../routing';
import { Container } from '../../../ui';
import { useQuestionnaire } from '../hooks';
import { SectionList } from './SectionList';
import { Section } from './Section';
import { Box, NestedBox, ActionBox, Label, Space, AnswerItem } from '../styled';
import { DependedDocument } from './DependedDocument';
import { RelatedQuestionnaire } from './RelatedQuestionnaire';
import { CheckTriggers } from './CheckTriggers';

const { TextArea } = Input;

export const Questionnaire = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {data, isError, isLoading, handleCreate, handleDelete, isDeleting, handleUpdate, isUpdating, handleChangeRole, isChangingRole} = useQuestionnaire(id);
  const [questionnaire, setQuestionnaire] = useState({});
  const [showCreateNew, setShowCreateNew] = useState(false);
  const [autoCheck, setAutoCheck] = useState({});
  const [isDepends, setIsDepends] = useState(false);
  const [isRelatedQuestionnaire, setIsRelatedQuestionnaire] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isModalRelatedQuestionnaireOpened, setIsModalRelatedQuestionnaireOpened] = useState(false);


  useEffect(() => {
    if (!data?.id) return;

    setQuestionnaire(data);
    if (data?.kycTypeDocuments?.length) setIsDepends(true);
    if (data?.relatedQuestionnaires?.length) setIsRelatedQuestionnaire(true);

  }, [data])

  const onChange = (e) => {
    const {name, value} = e.target;
    setQuestionnaire({...questionnaire, [name]: value})
  }

  const onDelete = () => {
    handleDelete(questionnaire);
    history.push(`/constructor/`);
  }

  const onChangeRole = (e) => {
    const {name, checked} = e.target;

    handleChangeRole(name, checked);
  }

  const onChangeIsRequired = (e) => {
    const {name, checked} = e.target;
    setQuestionnaire({...questionnaire, [name]: checked})
  }

  const onChangeProhibitAdding = (e) => {
    const {name, checked} = e.target;
    setQuestionnaire({...questionnaire, [name]: checked})
  }

  const onChangeAutoCheck = (e) => {
    const {name, checked} = e.target;
    setQuestionnaire({...questionnaire, [name]: checked});
    setAutoCheck(checked);
  }

  const handleAction = () => {
    if (questionnaire.id) {
      handleUpdate(questionnaire);
    } else {
      handleCreate(questionnaire).then((res) => {
        if(res?.data?.id) {
          history.push(`/constructor/questionnaires/${res.data.id}`);
        }
      });
    }
  }

  if (isError && !data) {
    return (
      <Container paddingY={24}>
        <div>
          {t('Error')}
        </div>
      </Container>
    );
  }

  const isDisabled = !questionnaire.type || (
    questionnaire.type === data?.type &&
    questionnaire.name === data?.name &&
    questionnaire.title === data?.title &&
    questionnaire.descriptions === data?.descriptions  &&
    questionnaire.isRequired === data?.isRequired &&
    questionnaire.percentAllowedCorrectAnswers === data?.percentAllowedCorrectAnswers &&
    questionnaire.autoCheck === data?.autoCheck &&
    questionnaire.prohibitAddingWithoutDependentQuestionnaire === data?.prohibitAddingWithoutDependentQuestionnaire
  )

  if (isLoading) return <div>Loading...</div>
  return (
    <Container paddingY={20}>
      {/*<Title2>{t('Questionnaire')}</Title2>*/}

      <Box isNew={true}>
        <Label>{t('Title')}</Label>
        <Input name="title" value={questionnaire.title} onChange={onChange} />
        <Space/>
        <Label>{t('Name')}</Label>
        <Input name="name" value={questionnaire.name} onChange={onChange} />
        <Space/>
        <Label>{t('Type')}</Label>
        <Input name="type" value={questionnaire.type} onChange={onChange} />
        <Space/>
        <Label>{t('Description')}</Label>
        <TextArea name="descriptions" value={questionnaire.descriptions} onChange={onChange} rows={4} />

        {questionnaire.id && (
          <>
            <Space/>
            <Label>{t('Roles')}</Label>
            <div style={{display: 'inline-block'}}>
              <Spin indicator={<LoadingOutlined spin />}
                    spinning={isChangingRole}
              >
                <Checkbox name="PERSONAL"
                          checked={questionnaire?.roles?.includes("PERSONAL")}
                          onChange={onChangeRole}
                          style={{marginRight: '15px'}}
                >
                  {t('PERSONAL')}
                </Checkbox>
                <Checkbox name="COMPANY"
                          checked={questionnaire?.roles?.includes("COMPANY")}
                          onChange={onChangeRole}
                          style={{marginRight: '15px'}}
                >
                  {t('COMPANY')}
                </Checkbox>
                <Checkbox name="AGENT"
                          checked={questionnaire?.roles?.includes("AGENT")}
                          onChange={onChangeRole}
                          style={{marginRight: '15px'}}
                >
                  {t('AGENT')}
                </Checkbox>
                <Checkbox name="RECOMMENDER"
                          checked={questionnaire?.roles?.includes("RECOMMENDER")}
                          onChange={onChangeRole}
                          style={{marginRight: '15px'}}
                >
                  {t('ASSISTANT')}
                </Checkbox>
              </Spin>
            </div>
          </>
        )}

        {questionnaire.id && <>
          <Space/>
          <Checkbox name="isRequired"
                    checked={questionnaire?.isRequired}
                    onChange={onChangeIsRequired}
          >
            {t('Is required')}
          </Checkbox>
          {/*<Space/>*/}
          {/*<Checkbox name="prohibitAddingWithoutDependentQuestionnaire"*/}
          {/*          checked={questionnaire?.prohibitAddingWithoutDependentQuestionnaire}*/}
          {/*          onChange={onChangeProhibitAdding}*/}
          {/*>*/}
          {/*  {t('Prohibit adding without dependent questionnaire')}*/}
          {/*</Checkbox>*/}

          <Space/>
          <Checkbox name="autoCheck"
                    checked={questionnaire?.autoCheck}
                    onChange={onChangeAutoCheck}
          >
            {t('Automatic check by the system')}
          </Checkbox>
        </>}
        {questionnaire.autoCheck && <>
          <Space/>
          <Label>{t('Percent Allowed Correct Answers(0-100%)')}</Label>
          <Input name="percentAllowedCorrectAnswers" type="number" min={0} max={100} step={1} value={questionnaire.percentAllowedCorrectAnswers} onChange={onChange} />
        </>}

        {questionnaire.id && <>
          <Space/>
          <Checkbox name="isDepends"
                    value={isDepends}
                    checked={isDepends}
                    onChange={e => setIsDepends(e.target.checked)}
          >
            {t('This questionnaire contains dependent document types')}
          </Checkbox>
          <Space/>

          {isDepends && (
            <>
              <Space/>
              <div>
                {questionnaire.kycTypeDocuments?.map((documentType, index) => (
                  <div key={index}>
                    <Input name={`documentType-${index}`} value={documentType.name} />
                    <Space />
                  </div>
                ))}
              </div>

              <Button onClick={() => setIsModalOpened(true)}
                      type="primary"
              >
                Select dependent document types
              </Button>
              <Modal
                title={"Select dependent document types"}
                width={700}
                style={{
                  top: 20,
                }}
                okText={'Add'}
                footer={null}
                open={isModalOpened}
                onCancel={() => setIsModalOpened(false)}
              >
                <DependedDocument
                  kycTypeDocuments={questionnaire.kycTypeDocuments}
                  questionSectionTypeId={questionnaire.id}
                />

              </Modal>
            </>
          )}
        </>}
        <Space/>


        {questionnaire.id && <>
          <Space/>
          <Checkbox name="isRelatedQuestionnaire"
                    value={isRelatedQuestionnaire}
                    checked={isRelatedQuestionnaire}
                    onChange={e => setIsRelatedQuestionnaire(e.target.checked)}
          >
            {t('This questionnaire depends on another questionnaire')}
          </Checkbox>
          <Space/>

          {isRelatedQuestionnaire && (
            <>
              <Space/>

              <Button onClick={() => setIsModalRelatedQuestionnaireOpened(true)}
                      type="primary"
              >
                Check Triggers
              </Button>
              <Modal
                title={'Triggers'}
                width={700}
                style={{
                  top: 20,
                }}
                okText={'Add'}
                footer={null}
                open={isModalRelatedQuestionnaireOpened}
                onCancel={() => setIsModalRelatedQuestionnaireOpened(false)}
              >
                <CheckTriggers
                  questionnaire={questionnaire}
                />

              </Modal>
            </>
          )}
        </>}

        <ActionBox>
          <Button onClick={handleAction}
                  disabled={isDisabled}
                  type="primary"
                  loading={isUpdating}
          >
            {questionnaire.id ? t('Update Questionnaire') : t('Create Questionnaire')}
          </Button>
          {questionnaire.id && questionnaire.createdAdmin && (
            <Popconfirm placement="topRight"
                        title={t('Are you sure to delete this questionnaire?')}
                        onConfirm={onDelete}
                        okText={t('Yes')}
                        cancelText={t('No')}
            >
              <Button danger loading={isDeleting}>{t('Delete Questionnaire')}</Button>
            </Popconfirm>
          )}
        </ActionBox>
      </Box>

      {questionnaire.id && (
        <Container paddingY={20}>
          <NestedBox>
            <SectionList questionnaireId={id} />
            <Space/>

            {showCreateNew ? (
              <Section onCreated={() => setShowCreateNew(false)}/>
            ) : (
              <Button onClick={() => setShowCreateNew(true)}>
                {t('Add new Section')}
              </Button>
            )}

          </NestedBox>
        </Container>
      )}

    </Container>
  );
};
