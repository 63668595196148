import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { SyncIndicator } from '@aragon/ui';
import { kycApi } from '../../api/kyc';
import { history } from '../../routing';
import { useMutation, useQuery } from 'react-query';
import { Toast } from '../kycModule/components/Toast';
import { QuestionnaireView } from './View';
import { kycHelpers } from '../../utils/helpers/kyc';
import { SHOWED_SECTIONS_COUNT, STATUSES_WITH_CLEAR } from '../../utils/static/kyc';
import { userActions } from '../../store/user';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

let appContainer = document.getElementById('app-container');

const getRejectedAnswersIds = (obj, questionId, answersIds, newAnswer) => {
  const resultArray = answersIds;
  Object.keys(obj).forEach(key => {

    if (key === 'questionId' && obj[key] === questionId && obj.childQuestionnaireId && obj.answer !== newAnswer.answer) {
      const duplicateIds = resultArray.filter((item) => item.childQuestionnaireId === obj.childQuestionnaireId);

      if (duplicateIds.length > 0) {
        duplicateIds.forEach((id) => id.answersIds.push(id.status === 'CREATED' || id.status === 'WITHOUT_CHANGE' ? newAnswer.id : id.status === 'DELETED' ? obj.id : null));
      } else {
        resultArray.push(
          {
            childQuestionnaireId: obj.childQuestionnaireId,
            status: 'CREATED',
            answersIds: [newAnswer.id]
          },
          {
            childQuestionnaireId: obj.childQuestionnaireId,
            status: 'DELETED',
            answersIds: [obj.id]
          }
        )
      }
    }
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      getRejectedAnswersIds(obj[key], questionId, resultArray, newAnswer)
    }
  })
  return resultArray;
}

export const Questionnaire = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { state } = useLocation();
  const { investor } = useSelector((state) => state);
  const [loader, setLoader] = useState(true);
  const [toast, setToast] = useState('');
  const [rejectedQuestions, setRejectedQuestions] = useState(null);
  const [rejectedSectionsIndexes, setRejectedSectionsIndexes] = useState(null);
  const [currentSection, setCurrentSection] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);

  const { mutateAsync: checkQuestionnaireStatus } = useMutation(kycApi.checkQuestionnaireStatus);
  const {
    data: res,
    isFetching
  } = useQuery(['getQuestionnaireSections'], () => kycApi.getQuestionnaireSections(id), {
    cacheTime: 'Infnity',
    onSuccess: async ({ data: questionnaire }) => {
      try {
        if (questionnaire?.status === 'QUESTION_UNACCEPTED' || questionnaire?.status === 'UNACCEPTED') {
          const res = await kycApi.getRejectedEntities();
          const rejectedQuestions = res?.data?.question;

          const { rejectedObj, sectionsIndexes } = kycHelpers.getRejectedObject(
            questionnaire,
            rejectedQuestions
          );

          if (rejectedObj) setRejectedQuestions(rejectedObj);
          if (questionnaire?.status === 'QUESTION_UNACCEPTED') {
            if (sectionsIndexes.length > 0) {
              setRejectedSectionsIndexes(sectionsIndexes);
              setCurrentSection(sectionsIndexes[0]);
            } else await nextStep();
          }
        }
      } catch (e) {
      } finally {
        setLoader(false);
      }
    }
  });
  const questionnaire = useMemo(() => (res ? res.data : null), [res]);

  useEffect(() => {
    dispatch(userActions.hideAppContainer());
    return () => dispatch(userActions.showAppContainer());
  }, []);

  const stepsCount = useMemo(() => {
    if (rejectedSectionsIndexes) return rejectedSectionsIndexes.length;
    if (questionnaire && questionnaire.questionSections)
      return Math.ceil(questionnaire.questionSections.length / SHOWED_SECTIONS_COUNT);
    return 0;
  }, [questionnaire, rejectedSectionsIndexes]);

  const onNext = async (data, insertedQuestions) => {
    try {
      setLoader(true);

      const { array, oneByOneRequests } = kycHelpers.collectCurrentAnswers({
        questionnaire,
        data,
        insertedQuestions,
        currentSection
      });

      const answersIds = [];

      const alreadyClearedQuestions = {};
      //post data
      if (oneByOneRequests) {
        const post = async (index) => {
          const thisAnswer = array[index];

          if (
            thisAnswer &&
            (thisAnswer.answer || thisAnswer.availableAnswerId || thisAnswer.file)
          ) {
            const { clearAll, prePostCallback, key, ...answer } = thisAnswer;

            if (prePostCallback) {
              answer[key] = await prePostCallback();
            }

            const res = await kycApi.saveQuestion({
              ...answer,
              clearAll: clearAll && !alreadyClearedQuestions[answer.questionId]
            });
            if (clearAll) alreadyClearedQuestions[answer.questionId] = true;

            if (rejectedQuestions) getRejectedAnswersIds(questionnaire, thisAnswer.questionId, answersIds, res.data);

            await post(index + 1);
          }
        };

        await post(0);

      } else {
        await Promise.all(
          array
          .filter((answer) => answer.answer || answer.availableAnswerId || answer.file)
          .map((answer) => new Promise(async (resolve, reject) => {
            try {
              const { prePostCallback, key, ...answerParams } = answer;

              if (prePostCallback) {
                answerParams[key] = await prePostCallback();
              }

              const res = await kycApi.saveQuestion(answerParams);

              if (rejectedQuestions) getRejectedAnswersIds(questionnaire, answer.questionId, answersIds, res.data);

              resolve();
            } catch (e) {
              reject();
            }
          }))
        );
      }

      console.log(answersIds)

      //change step after posting
      await nextStep(answersIds);
    } catch (e) {
      alert(e?.message);
      console.error(e);
    } finally {
      setLoader(false);
    }
  };

  const onBack = () => {
    if (currentSection > 0) setCurrentSection(currentSection - 1);
    else state && state.prevPath ? history.push(state.prevPath, state.nextState) : history.goBack();
  };

  const nextStep = async (answersIds) => {
    const finish = async () => {
      await checkQuestionnaireStatus({ id: id, ...(answersIds && answersIds.length > 0 && {rejectedSettings: answersIds}) });
      history.push('/profile', { lastProfileTab: investor.lastProfileTab });
    };

    if (rejectedSectionsIndexes) {
      const nextStepIndex = currentStep + 1;
      const stepsLength = rejectedSectionsIndexes.length;

      if (nextStepIndex < stepsLength) {
        setCurrentStep(nextStepIndex);
        setCurrentSection(rejectedSectionsIndexes[nextStepIndex]);
      } else {
        await finish();
      }
    }
    if (!rejectedSectionsIndexes) {
      if (currentSection + 1 < stepsCount) setCurrentSection(currentSection + 1);
      else await finish();
    }

    if (!appContainer) appContainer = document.getElementById('app-container');
    appContainer.parentNode.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const loading = (
    isFetching || !questionnaire || questionnaire.investorQuestionSectionTypeId !== id
  );
  return (
    <div>
      {!loading && (
        <QuestionnaireView
          questionnaire={questionnaire}
          currentSection={currentSection}
          onNext={onNext}
          onBack={onBack}
          rejectedQuestions={rejectedQuestions}
          setCurrentSection={setCurrentSection}
          disabled={investor?.accountStatus !== 'ACTIVE'}
        />
      )}

      <SyncIndicator visible={loader}>{t('Processing')}...</SyncIndicator>
      <Toast toast={toast} removeToast={() => setToast('')} />
    </div>
  );
};

export * from './View';
